import { NgModule } from '@angular/core';
import { LocaleModule } from '@x/common/locale';
import { ChannelSharedModule } from '../channel/channel-shared.module';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { IcarePaymentDialogComponent } from './components/icare-payment-dialog/icare-payment-dialog.component';
import { IframePaymentDialogComponent } from './components/iframe-payment-dialog/iframe-payment-dialog.component';
import { ManualPaymentDialogComponent } from './components/manual-payment-dialog/manual-payment-dialog.component';
import { OrderPaymentDialogComponent } from './components/order-payment/order-payment-dialog.component';
import { ORDER_PAYMENT_DIALOG_LANGUAGE } from './components/order-payment/order-payment-dialog.language';
import { OrderPaymentComponent } from './components/order-payment/order-payment.component';
// import { OrderPaymentsComponent } from './components/order-payments/order-payments.component';
import { ORDER_PAYMENTS_LANGUAGE } from './components/order-payments/order-payments.language';
import { PaymentMethodFormDialogComponent } from './components/payment-method-form/payment-method-form-dialog.component';
import { PaymentRefundDialogComponent } from './components/payment-refund-dialog/payment-refund-dialog.component';
import { PeachEftPaymentDialogComponent } from './components/peach-eft-payment-dialog/peach-eft-payment-dialog.component';
import { PAYMENT_LANGUAGE } from './payment.language';
import { PaymentsDialogService } from './services/payment-dialog.service';
import { PaymentProviderDialogService } from './services/payment-provider-dialog.service';

@NgModule({
  declarations: [
    PaymentMethodFormDialogComponent,
    OrderPaymentDialogComponent,
    IframePaymentDialogComponent,
    PeachEftPaymentDialogComponent,
    OrderPaymentComponent,
    PaymentRefundDialogComponent,
    ManualPaymentDialogComponent,
    IcarePaymentDialogComponent,
  ],
  imports: [
    EcommerceAdminCoreModule,
    LocaleModule.forChild([
      PAYMENT_LANGUAGE,
      ORDER_PAYMENTS_LANGUAGE,
      ORDER_PAYMENT_DIALOG_LANGUAGE,
    ]),
    ChannelSharedModule,
  ],
  providers: [PaymentsDialogService, PaymentProviderDialogService],
  exports: [OrderPaymentComponent],
})
export class PaymentsSharedModule {}
