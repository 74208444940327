import { LanguageDeclaration } from '@x/common/locale';

export const ORDER_PAYMENT_DIALOG_LANGUAGE: LanguageDeclaration = {
  orderPaymentDialog: {
    title: 'Make a payment',
    column: {
      description: 'Description',
      status: 'Status',
      method: 'Payment Method',
      hidden: 'Visibility',
      amount: 'Amount',
      timestamps: 'Timestamps',
    },
    action: {
      refund: 'Refund payment',
      cancel: 'Cancel payment',
      recon: 'Reconcile payment',
      pay: 'Pay',
    },
    confirm: {
      refund: {
        title: 'Confirm Payment Refund',
        message: 'Are you sure you want to refund this payment?',
        cancel: 'No',
        confirm: 'Yes',
      },
      cancel: {
        title: 'Confirm Payment Cancellation',
        message: 'Are you sure you want to cancel this payment?',
        cancel: 'No',
        confirm: 'Yes',
      },
    },
  },
};
