import { LanguageDeclaration } from '@x/common/locale';

export const PAYMENT_LANGUAGE: LanguageDeclaration = {
  payment: {
    state: {
      NEW: 'New',
      PROCESSING: 'Processing',
      AUTHORIZING: 'Authorizing',
      COMPLETED: 'Completed',
      REFUNDED: 'Refunded',
      FAILED: 'Failed',
      CANCELLED: 'Cancelled',
    },
    actions: {
      pay: 'Make a payment',
    },
  },
};
